import api from "./api";

export const listUsers = async () => {
    try {
      const response = await api.get('/admin/getAllUsers');
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const createUser = async (userData) => {
    // Placeholder for create user service
    console.log('Creating user:', userData);
    // TODO: Implement actual API call
  };
  
  export const getUser = async (userId) => {
    try {
      const response = await api.get(`/users/user-by-id/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  

  export const updateUser = async (userId, userData, profilePicture, wallBackground) => {
    try {
      const formData = new FormData();
      formData.append('userProfile', JSON.stringify(userData));
      
      if (profilePicture) {
        formData.append('profilePicture', profilePicture, profilePicture.name);
      }
      
      if (wallBackground) {
        formData.append('wallBackground', wallBackground, wallBackground.name);
      }
  
      const response = await api.put(`/users/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const deleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
    } catch (error) {
      throw error;
    }
  };