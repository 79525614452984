import api from './api';

export const createPost = async (formData) => {
  try {
    const response = await api.post('/admin/save-post', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Create post error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const editPost = async (id, formData) => {
  try {
    console.log("formData", formData);
    const response = await api.post(`/admin/edit-post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Edit post error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getFeed = async (newLastEvaluatedPk, newLastEvaluatedSk, limit = 5) => {
  try {
    const response = await api.get('/users/feeds', {
      params: { newLastEvaluatedPk, newLastEvaluatedSk, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Get feed error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deletePost = async (postId) => {
  try {
    const response = await api.delete(`post/delete-post/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Delete post error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const getPostById = async (postId) => {
  try {
    console.log("postId", postId);
    const response = await api.get(`/post/get-Post-byPostId/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching post:', error);
    throw error;
  }
};

// Add other post-related functions here in the future