import api from './api';

export const login = async (username, password) => {
  try {
    const response = await api.post('/users/login', { username, password });
    if (response.data && response.data.accessToken) {
      localStorage.setItem('token', response.data.accessToken);
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const getCurrentUser = () => {
  // You might want to decode the JWT token here to get user info
  // For now, we'll just return a boolean indicating if a token exists
  return !!localStorage.getItem('token');
};