// Home.js
import React from 'react';
import { Typography } from '@mui/material';

const Home = () => (
  <div>
    <Typography variant="h4" align="center" gutterBottom>
      Welcome to the Admin Portal
    </Typography>
    <Typography variant="h6" align="center">
      Choose an option from the menu to get started.
    </Typography>
  </div>
);

export default Home;
