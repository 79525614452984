// Sidebar.js
import React from "react";
import { Drawer, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";


const Sidebar = ({ drawerWidth }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/posts">
          <ListItemText primary="Posts" />
        </ListItem>
        <ListItem button component={Link} to="/create-post">
          <ListItemText primary="Create Post" />
        </ListItem>
        <ListItem button component={Link} to="/highlights">
          <ListItemText primary="Highlights" />
        </ListItem>
        <ListItem button component={Link} to="/create-highlight">
          <ListItemText primary="Create Highlight" />
        </ListItem>
        <ListItem button component={Link} to="/users">
          <ListItemText primary="Users" />
        </ListItem>
        {/* <ListItem button component={Link} to="/profile">
          <ListItemText primary="Profile" />
        </ListItem> */}
        <ListItem button  onClick={handleLogout} >
          <ListItemText primary="Logout" />
        </ListItem>
        {/* <ListItem button component={Link} to="/create-user">
        <ListItemText primary="Create User" />
      </ListItem> */}
      </List>
    </Drawer>
  );
};

export default Sidebar;
