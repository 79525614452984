import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { highlightService } from "../services/highlightService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "./ContentBlocks/ContentBlock";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";


const CreateHighlight = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [files, setFiles] = useState({});
  const [highlightData, setHighlightData] = useState({
    userId: user.userId,
    contentBlocks: [],
    contentType: "HIGHLIGHT",
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHighlightData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (blockIdentifier, newFiles) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [blockIdentifier]: newFiles,
    }));
  };

  const updateContentBlock = (index, updatedBlock) => {
    setHighlightData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setHighlightData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= highlightData.contentBlocks.length) return;
    setHighlightData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setHighlightData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Image", // Highlights typically use Image or Video types
          order: prev.contentBlocks.length + 1,
          imageContent: { url: "", caption: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const formData = new FormData();

    setHighlightData((prev) => ({
      ...prev,
      visibilityLevel: 'STATE',
    }))


    setHighlightData((prev) => ({
      ...prev,
      targetAudience: `STATE#Karnataka`,
    }))



    console.log(highlightData);

    formData.append("metadata", JSON.stringify(highlightData));

    Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
      blockFiles.forEach((file, index) => {
        formData.append(
          `file`,
          file,
          `file_${blockIdentifier}_${index}_${file.name}`
        );
      });
    });

    try {
      await highlightService.createHighlight(formData);
      // navigate("/highlights");
    } catch (err) {
      setError("Failed to create highlight. Please try again.");
      console.error("Create highlight error:", err);
    }
  };


  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Create New Highlight
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Content Type</InputLabel>
            <Select
              id="contentType"
              name="contentType"
              value={highlightData.contentType}
              label="Content Type"
              onChange={handleInputChange}
            >
              <MenuItem value="HIGHLIGHT">Highlight</MenuItem>
              <MenuItem value="EVENT">Event</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* Uncomment and adjust these sections if needed
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Target Audience</InputLabel>
            <Select
              id="targetAudience"
              name="targetAudience"
              value={highlightData.targetAudience.split("#")[0]}
              label="Target Audience"
              onChange={(e) =>
                setHighlightData((prev) => ({
                  ...prev,
                  targetAudience: `${e.target.value}#Karnataka`,
                }))
              }
            >
              <MenuItem value="SCHOOL">School</MenuItem>
              <MenuItem value="DISTRICT">District</MenuItem>
              <MenuItem value="STATE">State</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Visibility Level</InputLabel>
            <Select
              id="visibilityLevel"
              name="visibilityLevel"
              value={highlightData.visibilityLevel.split("#")[0]}
              label="Visibility Level"
              onChange={(e) =>
                setHighlightData((prev) => ({
                  ...prev,
                  visibilityLevel: `${e.target.value}`,
                }))
              }
            >
              <MenuItem value="SCHOOL">School</MenuItem>
              <MenuItem value="DISTRICT">District</MenuItem>
              <MenuItem value="STATE">State</MenuItem>
              <MenuItem value="PUBLIC">Public</MenuItem>
            </Select>
          </FormControl>
        </Box>
        */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {highlightData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Create Highlight
        </Button>
      </form>
    </Container>
  );
};

export default CreateHighlight;