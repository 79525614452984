import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header>
      <nav>
        <ul>
          {user ? (
            <>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/posts">Posts</Link></li>
              <li><Link to="/create-post">Create Post</Link></li>
              <li><Link to="/highlights">Highlights</Link></li>
              <li><Link to="/create-highlight">Create Highlight</Link></li>
              <li><Link to="/profile">Profile</Link></li>
              <li><button onClick={handleLogout}>Logout</button></li>
            </>
          ) : (
            <>
              <li><Link to="/login">Login</Link></li>
              <li><Link to="/register">Register</Link></li>



              <li><Link to="/posts">Posts</Link></li>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/posts">Posts</Link></li>
              <li><Link to="/create-post">Create Post</Link></li>
              <li><Link to="/highlights">Highlights</Link></li>
              <li><Link to="/create-highlight">Create Highlight</Link></li>
              <li><Link to="/profile">Profile</Link></li>

              <li><Link to="/users"> UserList </Link></li>
          <li><Link to="/users/create" >CreateUser </Link></li>
          <li><Link to="/users/edit/:userId"> EditUser </Link></li>
         


              <li><button onClick={handleLogout}>Logout</button></li>

            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;