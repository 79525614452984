// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./pages/Home";
import PostList from "./pages/PostsList";
import CreatePost from "./pages/CreatePost";
import EditPost from "./pages/EditPost";

import HighlightsList from "./pages/HighlightsList";
import CreateHighlight from "./pages/CreateHighlight";
import EditHighlight from "./pages/EditHighlight";

import UserList from "./pages/UserList";
import CreateUser from "./pages/CreateUser";
import EditUser from "./pages/EditUser";

import { Box, CssBaseline } from "@mui/material";
import Login from "./components/Login";

const drawerWidth = 240; // Standard width for sidebar

function App() {
  const { user } = useAuth();

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* Header */}
        <Header drawerWidth={drawerWidth} />
        {/* Sidebar */}
        <Sidebar drawerWidth={drawerWidth} />
        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: 8, // Adjust if needed to clear the AppBar height
          }}
        >
          <Routes>
            <Route
              path="/login"
              element={user ? <Navigate to="/" /> : <Login />}
            />
            <Route
              path="/"
              element={user ? <Home /> : <Navigate to="/login" />}
            />
            <Route path="/posts" element={<PostList />} />
            <Route path="/highlights" element={<HighlightsList />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/create-post" element={<CreatePost />} />
            <Route path="/edit-post/:id" element={<EditPost />} />
            <Route path="/create-highlight" element={<CreateHighlight />} />
            <Route path="/edit-highlight/:id" element={<EditHighlight />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/create-user" element={<CreateUser />} />
            <Route path="/edit-user/:userId" element={<EditUser />} />

            {/* Add more routes as needed */}
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
