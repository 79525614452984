// src/services/highlightService.js
import api from './api';

export const highlightService = {
  getAllHighlights: async () => {
    const response = await api.get('/users/get-UserHighlightFeeditems');
    return response.data;
  },

  getHighlightById: async (id) => {
    const response = await api.get(`/highlights/gethighlightbyId?highlightId=${id}`);
    return response.data;
  },

  createHighlight: async (highlightData) => {
    const response = await api.post('/admin/save-Highlight', highlightData);
    return response.data;
  },

  // updateHighlight: async (id, highlightData) => {
  //   // Assuming there's an update endpoint. You may need to adjust this based on the actual API.
  //   console.log(highlightData);
  //   const response = await api.post(`/admin/edit-highlight`, highlightData);
  //   return response.data;
  // },

  updateHighlight: async (id, highlightData) => {
    const response = await api.post(`/admin/edit-highlight`, highlightData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },

  deleteHighlight: async (id) => {
    // Assuming there's a delete endpoint. You may need to adjust this based on the actual API.
    const response = await api.delete(`/highlights/delete-Highlight/${id}`);
    return response.data;
  },

  likeHighlight: async (highlightId, contentBlockId) => {
    const response = await api.post(`/highlights/like?highlightId=${highlightId}&contentBlockId=${contentBlockId}`);
    return response.data;
  },

  dislikeHighlight: async (highlightId, contentBlockId) => {
    const response = await api.post(`/highlights/dislike?highlightId=${highlightId}&contentBlockId=${contentBlockId}`);
    return response.data;
  },
};

export default highlightService;